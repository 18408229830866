<template>
  <div class="add-cabin-content">
    <AddCabinImage :form="form" @imageUpload="handleImageUpload"></AddCabinImage>
    <section class="add-cabin">
      <h1>Legg til hytte</h1>
      <row container :gutter="20">
        <column :xs="12" :md="12" :lg="6">
          <form @submit.prevent="createCabin()">
            <div class="input-group">
              <label for="name">Hyttenavn</label>
              <input type="text" class="input" name="name" v-model="form.name" />
            </div>
            <div class="input-group">
              <label for="name">Søk etter lokasjon</label>
              <GMapAutocomplete placeholder="Skriv inn adressen..." @place_changed="setPlace"></GMapAutocomplete>
            </div>
            <div class="input-group">
              <label for="name">Adresse</label>
              <input type="text" class="input" name="name" v-model="form.address" />
            </div>
            <div class="map-container">
              <GMapMap
                :center="currentLocation"
                :zoom="8"
                :options="{
                              zoomControl: false,
                              mapTypeControl: false,
                              scaleControl: false,
                              streetViewControl: false,
                              rotateControl: false,
                              fullscreenControl: false,
                          }"
                map-type-id="terrain"
                style="width: 100%; height: 100%"
              >
                <GMapMarker
                  :key="index"
                  v-for="(marker, index) in markers"
                  :position="marker.position"
                />
              </GMapMap>
            </div>
            <!-- <div class="input-group">
                          <label for="name">Postnummer</label>
                          <input
                              type="text"
                              class="input"
                              name="name"
                              v-model="form.postcode"
                          >
            </div>-->
            <Button type="submit" text="Legg til hytte" color="green" :disabled="disabled"></Button>
          </form>
        </column>
      </row>
    </section>
  </div>
</template>
<script>
import Button from '@/components/Button';
import AddCabinImage from '@/components/AddCabinImage';
import CabinService from '@/services/CabinService';

export default {
  components: {
    Button,
    AddCabinImage
  },
  data() {
    return {
      form: {
        name: '',
        address: '',
        postcode: '',
        latitude: 59.91333,
        longitude: 10.73897
      },
      disabled: false,
      cabinImageObject: undefined
    };
  },
  computed: {
    currentLocation() {
      return {
        lat: this.form.latitude,
        lng: this.form.longitude
      };
    },
    markers() {
      return [
        {
          position: {
            lat: this.form.latitude,
            lng: this.form.longitude
          }
        }
      ];
    }
  },
  methods: {
    createCabin() {
      this.disabled = true;
      let requestData = {
        name: this.form.name,
        ownerId: this.$store.getters['users/userId'],
        address: this.form.address,
        postcode: this.form.postcode,
        longitude: this.form.longitude,
        latitude: this.form.latitude
      };
      CabinService.addCabin(...Object.values(requestData))
        .then(async res => {
          console.log('Successfully created cabin', res);
          if(this.cabinImageObject) {
            await CabinService.addCabinContent(res.data.id, 1, 5, 'Image', this.cabinImageObject.base64, this.cabinImageObject.name, true, 1).then(res => {
              console.log('Successfully added cabin content/image', res);
            }).catch(err => {
              console.log('Could not add cabin content/image', err)
            })
          }
          this.$router.push({ name: 'Cabins' });
          this.$toast.success(`Hytte opprettet`);
        })
        .catch(err => {
          console.log(err);
          this.$toast.error(`Kunne ikke opprette hytte`);
          this.disabled = false;
        });
    },
    setPlace(val) {
      this.form.address = val.formatted_address;
      this.form.latitude = val.geometry.location.lat();
      this.form.longitude = val.geometry.location.lng();
    },
    handleImageUpload(val) {
      console.log(val);
      this.cabinImageObject = val;
    }
  }
};
</script>
<style lang="scss" scoped>
.add-cabin {
  @include gutter-padding;
  margin: 4rem 0;
  .map-container {
    margin-top: 1.5rem;
  }
  .pac-target-input {
    background-color: $hytta;
    color: white;
  }
  .pac-target-input::placeholder {
    color: white;
  }
  .button {
    width: 100%;
  }
}
</style>
