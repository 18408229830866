<template>
  <div class="hero" :style="{ 'background-image': `url(${heroImage})` }">
    <div class="file-upload-container">
      <label for="file-upload" class="upload-image">
        <i class="fa fa-cloud-upload"></i> Last opp bilde
      </label>
      <input
        id="file-upload"
        type="file"
        @change="onFileChange($event)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AddCabinImage',
  props: {
    form: Object
  },
  data() {
    return {
      fileSelected: null,
      heroImage: null
    }
  },
  methods: {
    onFileChange(event) {
      const selectedImage = event.target.files[0];
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.heroImage = event.target.result;
        let splitImage = this.heroImage.split(',');
        let savingFileObject = {
          name: fileObject.name,
          base64: splitImage[1]
        }
        this.$emit('imageUpload', savingFileObject);
      }
      reader.readAsDataURL(fileObject);
    }
  }
};
</script>
<style lang="scss" scoped>
.hero {
  position: relative;
  img {
    max-width: 100%;
  }
  background-color: $light-grey;
  background-position: center;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  @media (min-width: $breakpoint-mobile) {
    padding-bottom: 30%;
    border-radius: 8px;
  }
  background-size: cover;
  z-index: 0;

  .file-upload-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    input[type="file"] {
      display: none;
    }
    .upload-image {
      font-size: 13px;
      font-weight: 600;
      border-radius: 12px;
      padding: 0.75rem 1rem;
      background-color: $hytta;
      color: white;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
</style>
